<script setup lang="ts">
    const props = defineProps<{
      title: string,
      content: string,
      bgWhite?: boolean,
      isContentHtml?: boolean
    }>();
</script>
<template>
  <div :class="`w-100 py-5 ${props.bgWhite ? 'bg-white' : 'bg-wh-brown'}`">
    <div class="container-xl py-5">
      <div class="row">
        <div class="col-md-4">
          <h3 class="category-label mb-4">{{ props.title }}</h3>
          <div class="text-dark"><hr/></div>
        </div>
        <div class="col-md-8 ps-md-5 headline-content-wrapper">
          <span v-if="props.isContentHtml" class="headline-content" v-html="props.content"></span>
          <span v-else class="headline-content">{{ props.content }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
